import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductService } from '../product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductModel } from '../models/Product.model';
import { CategoryService } from 'src/app/services/category.service';
import { CategoryModel } from '../models/category.model';
import { CartOneComponent } from '../../cart/cart-one/cart-one.component';
import { SessionService } from '../../session/session.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CategoryFeaturesService } from 'src/app/services/category.features.service';
import { CategoryFeaturesModel } from '../models/category.features.model';
import { CategoryFeaturesListModel } from '../models/category.features.list.model';
@Component({
  selector: 'app-shop-one',
  templateUrl: './shop-one.component.html',
  styleUrls: ['./shop-one.component.scss']
})
export class ShopOneComponent implements OnInit {
  @ViewChild('swap',{static:false}) swap: ElementRef;

  products: ProductModel[] = [];
  AllProducts: ProductModel[] = [];
  categories: CategoryModel[] = [];
  categoryFeaturesList: CategoryFeaturesListModel[] = [];
  categoriesObject: CategoryModel={};
  count = 0;
  currenPage = 1;
  pages: number [] = [] ;
  public current_language;
  CityId = '';
  pageOfItems: Array<any>;
  items = [];

  filteredArray: any[] = []
  defaultRecords: any = 4;

  slideConfigCarousel = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "arrows" : false,

    "autoplay":true,
    "autoplaySpeed":3000,
    "speed":600,
    
    "pauseOnHover":false,

    "pauseOnDotsHover":true,
    // "cssEase":'linear',
    //"fade":true,
    // "draggable":false,
    // "prevArrow":'<button class="PrevArrow"></button>',
    // "nextArrow":'<button class="NextArrow"></button>', 

    responsive: [
      {
      breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
      breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private sessionService:SessionService,
    private sanitization: DomSanitizer,
    private categoryFeatures:CategoryFeaturesService,
  ) { 
    this.CategoryId = + route.snapshot.paramMap.get('Id');
    this.currenPage = + route.snapshot.paramMap.get('page');
    this.current_language = localStorage.getItem('lang');
  }
  CategoryId = 0;
  display="grid_4column";
  changeDisplay(display){
    this.display = display;
  }
  onPaginateChange(data) {
     
    if(data.pageIndex > data.previousPageIndex){
      this.filteredArray = this.products.slice((data.previousPageIndex * data.pageSize), (data.pageIndex * data.pageSize));
    }
    else{
      this.filteredArray = this.products.slice((data.pageIndex * data.pageSize), (data.previousPageIndex * data.pageSize));
    }
    
  }
  CategoryImages:SafeStyle;
  ngOnInit() {
    if(this.sessionService.loggedIn == true){
      this.CityId = '0';
    }
    else{
      this.CityId = localStorage.getItem('CityId');
    }
    // this.productService.GetCountByCategoryId(this.CategoryId,+this.CityId)
    // .subscribe(count => {
    //   this.count = count;
    //   let length = this.count / 8;
    //   if(length % 8){
    //     length ++;
    //   }
    //   for (let index = 1; index <=  length ; index ++) {
    //     this.pages.push(index);
    //    }
    // });
    this.productService.GetProductsForUser(this.CategoryId, 1)
    .subscribe(data => {
       
      this.products = data;
      this.filteredArray = [];
      this.filteredArray = this.products.slice(0, 12);
      // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
      this.count = this.products.length;
      let length = this.count / 12;
      if(length % 12){
        length ++;
      }
      for (let index = 1; index <=  length ; index ++) {
        this.pages.push(index);
       }
      this.products.forEach(element => {
        element.amount = 1;
      });
    });
    this.categoryService.GetAllForDropdownMenu()
    .subscribe(data => {
      this.categories = data;
      this.categories.forEach(element => {
        if(element.hideFromDropdownMenu == true){
          var i = this.categories.findIndex(x=>x.id == element.id);
          if(i > -1){
            this.categories.splice(i,1);
          }
        }
      });
    });
    this.categoryFeatures.GetByCategoryId(this.CategoryId).subscribe(data=>{
      this.categoryFeaturesList=data;
      console.log(this.categoryFeaturesList,"categoryFeaturesList")

    })
    this.categoryService.GetById(this.CategoryId).subscribe(data=>{
      this.categoriesObject=data;
      // this.CategoryImages=data.imageUrl;
      this.CategoryImages = this.sanitization.bypassSecurityTrustStyle(`url(${data.imageUrl})`);
      console.log(this.categoriesObject,"categoriesObject")
    })
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
}
  getByCategoryId(){
    this.currenPage = 1;
    this.pages=[];
    // this.productService.GetCountByCategoryId(this.CategoryId,+this.CityId)
    // .subscribe(count => {
    //   this.count = count;
    // });
    this.productService.GetProductsForUser(this.CategoryId, this.currenPage)
    .subscribe(data => {
      this.products = data;
      this.filteredArray = [];
      this.filteredArray = this.products.slice(0, 8);
      // this.products = Array(this.products.length).fill(0).map((x, i) => (new ProductModel({amount:x.amount,categoryNameAr : x.categoryNameAr,categoryNameEn:x.categoryNameEn,imageUrl:x.imageUrl,images:x.images,price:x.price,productId:x.productId,productNameAr:x.productNameAr,productNameEn:x.productNameEn,productUnits:x.productUnits,quantity:x.quantity,smallDescriptionAr:x.smallDescriptionAr,smallDescriptionEn:x.smallDescriptionEn})));
      this.count = this.products.length;
      let length = this.count / 12;
      if(length % 12){
        length ++;
      }
      for (let index = 1; index <=  length ; index ++) {
        this.pages.push(index);
       }
      this.products.forEach(element => {
        element.amount = 1;
      });
    });
  }
  changePage(page: number) {
     
    this.currenPage = page;
    var start = page*12;
    this.filteredArray = this.products.slice(start-12 , start)
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  incrementPage() {
    if (this.currenPage < this.pages.length) {
      this.currenPage ++ ;
      var start = this.currenPage*12;
      this.filteredArray = this.products.slice(start-12 , start)
    //   console.log('display',this.display);
    //   this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    //   .subscribe(data => {
    //     this.products = data;
    // });
    }
  }
  decrementPage() {
  if (this.currenPage > 1) {
    this.currenPage -- ;
    var start = this.currenPage*12;
    this.filteredArray = this.products.slice(start-8 , start)
    // console.log('display',this.display);
    // this.productService.GetProductsForUser(this.CategoryId, this.currenPage,+this.CityId)
    // .subscribe(data => {
    //   this.products = data;
    // });
  }
  }
  myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
  
    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more"; 
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less"; 
      moreText.style.display = "inline";
    }
  }
}
