import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';


import { ComponentsModule } from '../../components/components.module';
import { HeaderModule } from '../../layouts/header/header.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';


import { ShopOneComponent } from './shop-one/shop-one.component';
import { ShopTwoComponent } from './shop-two/shop-two.component';
import { ShopThreeComponent } from './shop-three/shop-three.component';
import { ShopFourComponent } from './shop-four/shop-four.component';
import { ShopFiveComponent } from './shop-five/shop-five.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShopSearchComponent } from './shop-search/shop-search.component';
import { BrowserModule } from '@angular/platform-browser';  
import { JwPaginationComponent } from 'jw-angular-pagination';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [JwPaginationComponent,ShopOneComponent, ShopTwoComponent, ShopThreeComponent, ShopFourComponent, ShopFiveComponent, ShopSearchComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule,
    ComponentsModule,
    HeaderModule,
    FooterModule,
    MatPaginatorModule,
    SidebarModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    SlickCarouselModule,
  ],
  exports: [ShopOneComponent, ShopTwoComponent, ShopThreeComponent, ShopFourComponent, ShopFiveComponent, ShopSearchComponent]
})
export class ShopModule { }
