import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CityService } from 'src/app/pages/session/city.service';
import { CityModel } from 'src/app/pages/session/model/city.model';
import { environment } from 'src/environments/environment';
import { ApplyJobService } from '../../apply-job.service';
import { JobService } from '../../job.service';
import { ApplyJobModel } from '../../models/apply-job.model';
import { JobModel } from '../../models/job.model';
import { ApplyJobComponent } from '../apply-job/apply-job.component';

@Component({
    // moduleId: module.id,
    selector: 'jop-details',
    templateUrl: 'jop-details.component.html',
    styleUrls: ['jop-details.component.scss']
})
export class JopDetailsComponent implements OnInit {
    @Output() public onUploadFinished = new EventEmitter();
    baseUrl = environment.DevApiUrl;
    DevImageUrl = environment.DevImageUrl;
    public response: {fileName: ''};
    public progress: number;
    public message: string;
    ApplyJob: ApplyJobModel;
    Form: FormGroup;
    id = 0;
    job: JobModel = {};
    openForm = false;
    pageTitle = '';
    imageUrl = '';
    openModel = false;
    isLogin = false;
  public current_language;
    constructor(
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService,
        private applyJobService: ApplyJobService,
        public toaster: ToastrService,
        private fb: FormBuilder,
    private cityService: CityService,
    private http: HttpClient,
    ){
      this.current_language = localStorage.getItem('lang');
        this.id = + route.snapshot.paramMap.get('Id');
        const token = localStorage.getItem('token');
        if(token != null){
          this.isLogin = true;
        }
    }
  cities: CityModel[] = [];

    ngOnInit(): void {
       this.jobService.GetById(this.id)
       .subscribe(data => {
           this.job = data;
           this.cityService.getAll(0)
           .subscribe(data => {
             this.cities = data;
           this.openForm = true;
          });
       });
    }
    apply() {
     
        this.pageTitle = 'Apply Job';
        this.ApplyJob = new ApplyJobModel();
        this.ApplyJob.jobId = this.id;
        this.imageUrl = this.ApplyJob.fileUrl ;
        this.ApplyJob.jobTitle = this.job.titleEn;
        this.Form = this.createForm();
      }
      createForm(): FormGroup {
        this.openModel = true;
        return this.fb.group({
          id: [this.ApplyJob.id],
          address: [this.ApplyJob.address, Validators.required],
          email: [this.ApplyJob.email , Validators.required],
          fileUrl: [this.ApplyJob.fileUrl],
          jobId: [this.ApplyJob.jobId],
          jobTitle: [this.ApplyJob.jobTitle],
          name: [this.ApplyJob.name, Validators.required],
          phone: [this.ApplyJob.phone, Validators.required],
          gender: [this.ApplyJob.gender, Validators.required],
          age: [null, Validators.required],
          cityId: [null, Validators.required],
          nationalltiy: [this.ApplyJob.nationalltiy, Validators.required],
        });
      }
            onSubmit(): void {
              //debugger;
              this.ApplyJob = this.Form.getRawValue();
              this.ApplyJob.fileUrl = this.imageUrl ;
              this.applyJobService.createApplyJob(this.ApplyJob)
              .subscribe(data => {
                  this.toaster.success('Send Done Success');
                  this.ApplyJob = new ApplyJobModel();
                  this.imageUrl = '';
              });
          }
          public uploadFile = (files) => {
              //debugger;
              if (files.length === 0) {
                return;
              }
         
              const fileToUpload = files[0] as File;
              var ext = fileToUpload.name.split('.').pop();
              if(ext=="pdf" || ext=="docx" || ext=="doc"){
                const formData = new FormData();
                formData.append('file', fileToUpload, fileToUpload.name);
                this.http.post(`${this.baseUrl}/ApplyJob/UploadCV`, formData, {reportProgress: true, observe: 'events'})
                  .subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress){
                        this.progress = Math.round(100 * event.loaded / event.total);
                    }
                    else if (event.type === HttpEventType.Response) {
                      this.message = 'Upload success.';
                      this.onUploadFinished.emit(event.body);
                      this.uploadFinished(event.body);
                    }
                  });
              } else{
                if(this.current_language != 'ar'){

                  this.toaster.error("Please Upload Only Document Files")
                }
                else{
                  this.toaster.error("الرجاء تحميل ملفات فقط")

                }
              }
          
            }
            public uploadFinished = (event) => {
              this.response = event;
              this.imageUrl = `${this.DevImageUrl}/Resources/Files/` + event;
            }
            deleteImage(): void {
                this.imageUrl = '';
            }

}
