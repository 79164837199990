import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ApplyJobModel } from '../../models/apply-job.model';
import { JobModel } from '../../models/job.model';

@Component({
    // moduleId: module.id,
    selector: 'apply-job',
    templateUrl: 'apply-job.component.html',
    styleUrls: ['apply-job.component.scss']
})
export class ApplyJobComponent implements OnInit {
    @Output() public onUploadFinished = new EventEmitter();
    baseUrl = environment.DevApiUrl;
    DevImageUrl = environment.DevImageUrl;
    public response: {fileName: ''};
    public progress: number;
    public message: string;
    openForm:boolean=false;
    imageUrl:string='';
    @Input()
    data: { type: string; Job: JobModel};
      pageTitle: string;
      Job: ApplyJobModel;
      Form: FormGroup;
      toggle = false;
      constructor(
        public activeModal: NgbActiveModal,
        private fb: FormBuilder,
        private http: HttpClient,
      )
    {}
    createForm(): FormGroup {
      this.openForm = true;
      return this.fb.group({
        id: [this.Job.id],
        address: [this.Job.address,Validators.required],
        email: [this.Job.email,Validators.required],
        fileUrl: [this.Job.fileUrl],
        jobId: [this.Job.jobId],
        jobTitle: [this.Job.jobTitle],
        name: [this.Job.name,Validators.required],
        phone: [this.Job.phone,Validators.required],
        gender: [this.Job.gender,Validators.required],
        age: [this.Job.age,Validators.required],
        city: [this.Job.city,Validators.required],
        nationalltiy: [this.Job.nationalltiy,Validators.required],
      });
    }
          onSubmit(): void {
            
            this.Job = this.Form.getRawValue();
            this.Job.fileUrl = this.imageUrl ;
            this.activeModal.close(this.Job);
        }
        ngOnInit(): void {
            this.pageTitle = this.data.type;
            this.Job.jobId = this.data.Job.id;
            this.imageUrl = this.Job.fileUrl ;
            this.Job.jobTitle = this.data.Job.titleEn;
            this.Form = this.createForm();
        }
        public uploadFile = (files) => {
            if (files.length === 0) {
              return;
            }
            let fileToUpload = <File>files[0];
            const formData = new FormData();
            formData.append('file', fileToUpload, fileToUpload.name);
            this.http.post(`${this.baseUrl}/ApplyJob/UploadCV`, formData, {reportProgress: true, observe: 'events'})
              .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress){
                    this.progress = Math.round(100 * event.loaded / event.total);
                }
                else if (event.type === HttpEventType.Response) {
                  this.message = 'Upload success.';
                  this.onUploadFinished.emit(event.body);
                  this.uploadFinished(event.body);
                }
              });
          }
          public uploadFinished = (event) => {
            this.response = event;
            this.imageUrl=`${this.DevImageUrl}/Resources/Files/` + event;
          }
          deleteImage(): void {
              this.imageUrl = '';
          }
  }