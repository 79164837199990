import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CategoryFeaturesModel } from '../pages/shop/models/category.features.model';
import { CategoryFeaturesListModel } from '../pages/shop/models/category.features.list.model';

@Injectable({
  providedIn: 'root'
})
export class CategoryFeaturesService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(page:number): Observable<CategoryFeaturesModel[]> {
 
    //debugger;
    return this.http
      .get<CategoryFeaturesModel[]>(`${this.baseUrl}/CategoryFeatures/GetAll/${page}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/CategoryFeatures/GetCount`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  insert(payload: CategoryFeaturesModel): Observable<boolean> {
    console.log(payload);
    //debugger;
    return this.http
      .post<boolean>(`${this.baseUrl}/CategoryFeatures/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  update(payload: CategoryFeaturesModel): Observable<boolean> {
    //debugger;
    return this.http
      .put<boolean>(`${this.baseUrl}/CategoryFeatures/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: CategoryFeaturesModel): Observable<boolean> {
    //debugger;
    return this.http
      .delete<boolean>(`${this.baseUrl}/CategoryFeatures/Delete/${payload.id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
     GetByCategoryId(categoryId: number): Observable<CategoryFeaturesListModel[]> {
      return this.http
        .get<CategoryFeaturesListModel[]>(`${this.baseUrl}/CategoryFeaturesList/GetByCategoryId/${categoryId}`,this.httpOptions)
        .pipe(catchError((error: any) => Observable.throw(error.json())));
    }
}
