import { BoxProductsModel } from './../../models/box-products.model';
import { BoxModel } from './../../models/box.model';
import { BoxService } from './../../box.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KatoraService } from 'src/app/services/katora.service';
import { CartModel } from 'src/app/pages/user-account/models/Cart.model';
import { BoxCartModel } from 'src/app/pages/user-account/models/box-cart.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'create-my-box',
    templateUrl: 'create-my-box.component.html',
    styleUrls: ['create-my-box.component.scss']
})
export class CreateMyBoxComponent implements OnInit {
    current_language = '';
    Id = 0;
    box:BoxModel={};
    cartModel: BoxCartModel = {};
    button = 'Add To Cart'
  options: FormGroup;

    selectedProduct:BoxProductsModel[]=[];
    constructor(
        private boxService: BoxService,
        private route: ActivatedRoute,
         private katoraService: KatoraService,
        public router:Router,
        fb: FormBuilder
        ) {
        // this.loadAlbum();
        this.current_language = localStorage.getItem('lang');
        this.Id = + route.snapshot.paramMap.get('Id');
        this.options = fb.group({
            bottom: 0,
            fixed: true,
            top: 0
          });
      }
    ngOnInit(): void {
        //debugger;
        this.boxService.GetById(this.Id)
        .subscribe(data => {
            this.box = data;
            this.avelableQuentity = this.box.weight;
            this.box.products.forEach(element => {
                element.quantity = 0;
            });
            var b = this.katoraService.getBoxFromCart(this.Id);
            if(b.id>0){
                this.avelableQuentity = 0;
                this.progressvalue = 100;
                this.totalvalue = 0;
                this.button = 'Update On Cart'
                this.selectedProduct = b.products;
                this.box.products.forEach(element => {
                    var i = this.selectedProduct.findIndex(x=>x.id == element.id);
                    if(i>-1){
                        element.quantity = b.products[i].quantity;
                        this.totalvalue +=element.quantity * element.perPieceWeight;
                    }
                    else{
                        element.quantity = 0;
                    }
                });  
            }
        })
       
    }
    openWarningTape=false;
    addQuantity(product){
        debugger;
        var i = this.box.products.indexOf(product);
        if(i > -1){
            this.box.products[i].quantity ++;
            this.totalvalue += this.box.products[i].perPieceWeight;
            this.progressvalue = (this.totalvalue / this.box.weight)*100;
            this.avelableQuentity -= this.box.products[i].perPieceWeight;
        }
        var found= this.selectedProduct.indexOf(product);
        if(found == -1){
            this.selectedProduct.push(product);
        }
        this.box.products.forEach(element => {
            if(this.avelableQuentity < element.perPieceWeight){
                this.openWarningTape = true;
            }
        });
    }
    removeQuantity(product){
        debugger;
        var i = this.box.products.indexOf(product);
        if(i > -1){
            this.openWarningTape = false;
            this.box.products[i].quantity --;
            this.totalvalue -= this.box.products[i].perPieceWeight;
            this.progressvalue = (this.totalvalue / this.box.weight)*100;
            this.avelableQuentity += this.box.products[i].perPieceWeight;
            if(this.box.products[i].quantity == 0){
                var fount = this.selectedProduct.indexOf(product);
                if(fount > -1){
                    this.selectedProduct.splice(fount,1);
                }
            }
            this.box.products.forEach(element => {
                if(this.avelableQuentity < element.perPieceWeight){
                    this.openWarningTape = true;
                }
            });
        }
    }
    removeProduct(product){
        debugger;
        this.openWarningTape = false;

        var i = this.box.products.indexOf(product);
        if(i > -1){
            this.totalvalue -= this.box.products[i].perPieceWeight * this.box.products[i].quantity;
            this.progressvalue = (this.totalvalue / this.box.weight)*100;
            this.avelableQuentity += this.box.products[i].perPieceWeight * this.box.products[i].quantity;
            this.box.products[i].quantity = 0;
            if(this.box.products[i].quantity == 0){
                var fount = this.selectedProduct.indexOf(product);
                if(fount > -1){
                    this.selectedProduct.splice(fount,1);
                }
            }
            this.box.products.forEach(element => {
                if(this.avelableQuentity < element.perPieceWeight){
                    this.openWarningTape = true;
                }
            });
        } 
    }
    progressvalue=0;
    totalvalue = 0;
    avelableQuentity = 0;

    onAddCart(product: BoxModel) {
        this.cartModel.id = product.id;
        this.cartModel.imageUrl = product.imageUrl;
        this.cartModel.boxId = product.id;
        this.cartModel.totalPrice = product.price;
        this.cartModel.quantity = 1;
        this.cartModel.boxNameAr = product.nameAr;
        this.cartModel.boxNameEn = product.nameEn;
        this.cartModel.products = this.selectedProduct;
        if(this.button == 'Update On Cart'){

            this.katoraService.updateboxToCart(this.cartModel);
        }
        else{
            this.katoraService.addboxToCart(this.cartModel);
        }
        this.router.navigate(['cart-one'])
        
      }
      shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
   
      
     floating1 = document.getElementById("floating1");
      
     yOffset = 67966;
    //  @HostListener('window:scroll', ['$event']) // for window scroll events

    //  myFunction(event) {
    //     //debugger;
    //     console.log(event.srcElement.scrollingElement);
    //     this.floating1 = document.getElementById("floating1");
    //     if (event.srcElement.scrollingElement.id !=  'divrow') {
    //     //   this.floating1.classList.remove("test3");

    //     } else {
    //       this.floating1.classList.add("test3");
    // }
    //   }
}
