import { ToastrService } from 'ngx-toastr';
import { GiftMessageDesignCategoryService } from './../../gift-message-design-category.service';
import { GiftMessageDesignCategoryModel } from './../../models/gift-message-design-category.model';
import { GiftCardModel } from './../../models/gift-card.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GiftCardService } from '../../gift-card.service';
import { GivingGiftModel } from '../../models/giving-gift.model';
import { DomSanitizer } from '@angular/platform-browser';
import { KatoraService } from 'src/app/services/katora.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { GivingGiftService } from '../../giving-gift.service';
import { EmailModel } from '../../models/email.model';

@Component({
    selector: 'giving-gift',
    templateUrl: 'giving-gift.component.html',
    styleUrls: ['giving-gift.component.scss']
})
export class GivingGiftComponent implements OnInit {
    @Output() public onUploadFinished = new EventEmitter();
    @Output() public onUploadVideo = new EventEmitter();
    backgroundColer=''
    slideConfigTabFeatured = {
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        infinite: false,
        arrows : false,
        responsive: [
          {
          breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
      slideConfigCarousel = {
        slidesToShow : 5,
        slidesToScroll: 5,
        dots: false,
        infinite: true,
        arrows : true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 1000,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        responsive: [
          {
          breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
          breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    
      };
    isEmail: 'false' | 'true' = 'true';
    GiftId = 0;
    step = 'pills-shipping';
    currentJustify = 'start';
    imageUrl = "";
    cardType = 'VISA';
    cardName = '';
    cardNumber = '';
    expirationMonth='';
    expirationYear='';
    securityCode = '';
    friendName  = '';
    friendEmail = '';
    friendPhone = '';
    gift : GiftCardModel = {};
    givingModel:GivingGiftModel = {};
    openCategories = false;
    category :GiftMessageDesignCategoryModel = {};
    categories : GiftMessageDesignCategoryModel[] = [];
    cards : GiftCardModel []=[];
    imageOrVideoType = 0;
  public current_language;
  emailModel : EmailModel = {};
    constructor(
        private giftService: GiftCardService,
        private route: ActivatedRoute,
        private designCategoryService: GiftMessageDesignCategoryService,
        private sanitization: DomSanitizer,
         private katoraService:KatoraService,
         private http: HttpClient,
        private givingGiftService : GivingGiftService,
        public toastr : ToastrService,
        private router:Router
      ) { 
        this.GiftId = + route.snapshot.paramMap.get('Id');
        this.givingModel = new GiftCardModel();
        this.current_language = localStorage.getItem('lang');
    }
      saveImage(item){
        return this.sanitization.bypassSecurityTrustStyle(`url(${item})`);
      }
    ngOnInit(): void {
        this.giftService.GetById(this.GiftId)
        .subscribe(data => {
            this.gift = data;
        });
        this.designCategoryService.getAll(0)
        .subscribe(data => {
            this.categories = data;
            this.openCategories = true;
            this.category = this.categories[0];
            this.category.images = this.categories[0].images;
            this.givingModel.categoryImageUrl = this.category.imageUrl;
        });
        this.cards = this.katoraService.getProductGiftCards();
    }
    chceck(){
    }
  getBoolean(value){
      switch(value){
           case true:
           case "true":
           case 1:
           case "1":
           case "on":
           case "yes":
               return true;
           default: 
               return false;
       }
      }
      chooseAmount(item){
         
        this.givingModel.amount = item.amount
        this.category = item;
        this.step = 'pills-billing';
      }
      chooseImage(imageUrl){
        this.givingModel.categoryImageUrl = imageUrl;
      }
      setStep(item:string){
         
        this.step = item;
      }
      baseUrl = environment.DevApiUrl;
      DevImageUrl=environment.DevImageUrl;
      public response: {fileName: ''};
        public progress: number;
        public message: string;
        public uploadFile = (files) => {
          this.givingModel.imageOrVideoType = 1;
          this.givingModel.videoUrl = ''
          this.imageOrVideoType = 1 ;
          if (files.length === 0) {
            return;
          }
          let fileToUpload = <File>files[0];
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);
          this.http.post(`${this.baseUrl}/GivingGift/UploadImage`, formData, {reportProgress: true, observe: 'events'})
            .subscribe(event => {
              if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
              else if (event.type === HttpEventType.Response) {
                this.message = 'Upload success.';
                this.onUploadFinished.emit(event.body);
                this.uploadFinished(event.body);
              }
            });
        }
        public uploadFinished = (event) => {
          this.response = event;
          this.givingModel.imageUrl=`${this.DevImageUrl}/Resources/GivingCardImages/`+event;
        }
        deleteImage():void{
          this.givingModel.imageUrl='';
        }
        public uploadVideo = (files) => {
          this.givingModel.imageOrVideoType = 2;
          this.givingModel.imageUrl = ''
          this.imageOrVideoType = 2 ;

          if (files.length === 0) {
            return;
          }
          let fileToUpload = <File>files[0];
          const formData = new FormData();
          formData.append('file', fileToUpload, fileToUpload.name);
          this.http.post(`${this.baseUrl}/GivingGift/UploadVideo`, formData, {reportProgress: true, observe: 'events'})
            .subscribe(event => {
              if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
              else if (event.type === HttpEventType.Response) {
                this.message = 'Upload success.';
                this.onUploadVideo.emit(event.body);
                this.uploadVideoFinished(event.body);
              }
            });
        }
        public uploadVideoFinished = (event) => {
          this.response = event;
          this.givingModel.videoUrl=`${this.DevImageUrl}/Resources/GivingCardVideos/`+event;
        }
        deleteVideo():void{
          this.givingModel.videoUrl='';
        }
        onPay(){
          //debugger;
          this.givingModel.amount          = this.givingModel.amount;
          this.givingModel.designCategoryId= this.givingModel.designCategoryId;
          this.givingModel.imageUrl        = this.givingModel.imageUrl;
          this.givingModel.categoryImageUrl= this.givingModel.categoryImageUrl;
          this.givingModel.videoUrl        = this.givingModel.videoUrl;
          this.givingModel.imageOrVideoType= this.imageOrVideoType;
          this.givingModel.message         = this.givingModel.message;
          this.givingModel.friendName      = this.friendName ;
          this.givingModel.friendEmail     = this.friendEmail;
          this.givingModel.friendPhone     = this.friendPhone;
          this.givingModel.paymentType     = 0;
          this.givingModel.cardName        = this.cardName;
          this.givingModel.cardNumber      =this.cardNumber;
          this.givingModel.expirationMonth =this.expirationMonth;
          this.givingModel.expirationYear  =this.expirationYear;
          this.givingModel.cardType        =this.cardType;
          this.givingModel.securityCode    =this.securityCode;
          this.givingModel.deleveryDate    = this.givingModel.deleveryDate;
          this.givingModel.isEmail         = this.isEmail == 'true' ? true : false;
          this.givingModel.cards           =this.cards;
          this.givingGiftService.createGivingGiftCard(this.givingModel)
          .subscribe(result => {
            if(result > 0 ){
              this.toastr.success('The gift has been sent successfully');
              this.router.navigate(['gift-invoice',result]);
            }
            else{
              this.toastr.error('Please Try Agian');
            }
          });
        }
        chooseCategory(item : GiftMessageDesignCategoryModel){
          this.givingModel.designCategoryId = item.id
          this.category = item;
          this.givingModel.categoryImageUrl = item.imageUrl;
          this.category.images = item.images;
        }
}
